import React from "react"
// Components
import { StaticQuery,graphql } from 'gatsby';
import Layout from "../components/layout"
import { Seo } from "../components/Seo"
import { Container1200 } from '../components/Container1200';
import { PageTitle } from '../components/PageTitle';
import { ShowcaseItem } from '../components/ShowcaseItem';
import { TitleWithLine } from '../components/TitleWithLine';
// Style
import {
  Blurb,
  BlurbBox,
  Intro,
  ProjectsBoxes,
  Projects
} from '../styles/pages-styles/projects.styles';

const OtherProjects = () => {
  const query = graphql`
    query ProjectsItemsQuery {
      allProjectsJson {
        edges {
          node {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 384
                  quality: 100
                  traceSVG: { color: "#BDBDBD" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            linkType
            target
            title
            url
          }
        }
      }
    }
  `;

  return (
    <Layout>
      <Seo title="Other Projects | DaveJudd.com" />
      <Intro id="intro">
        <Container1200>
          <PageTitle>Other Projects</PageTitle>
          <BlurbBox>
            <Blurb>Check out some of my recent projects.</Blurb>
          </BlurbBox>
        </Container1200>
      </Intro>
      <Projects id="projects">
        <Container1200>
          <TitleWithLine>Recent Projects</TitleWithLine>
          <ProjectsBoxes>
            <StaticQuery
              query={query}
              render={data => (
                  <>
                    {data.allProjectsJson.edges.map(({ node }) => (
                        <ShowcaseItem
                          key={node.id}
                          image={node.image.childImageSharp.fluid}
                          linkType={node.linkType}  
                          target={node.target}
                          to={node.url}
                        >
                          <h2>{node.title}{node.linkType === 'external' && <i className="fas fa-external-link-alt"></i>}</h2>
                        </ShowcaseItem>
                      )
                    )}
                  </>
              )}
            >
            </StaticQuery>
            {/* <ShowcaseItem
              image={"../images/other-projects/nintendo.jpg"}
              linkType="external"
              target="_blank"
              to="https://codepen.io/collection/nNqZpq"
            >
              <h2>Video Game Art & Animation<i className="fas fa-external-link-alt"></i></h2>
            </ShowcaseItem>
            <ShowcaseItem
              image={langtonCube}
              linkType="external"
              target="_blank"
              to="https://langtons-ant-cube.netlify.app/"
            >
              <h2>Langton's Ant on a Cube with Three.js<i className="fas fa-external-link-alt"></i></h2>
            </ShowcaseItem>
            <ShowcaseItem
              image={langton}
              linkType="external"
              target="_blank"
              to="https://codepen.io/SirDaev/full/rOmNPR"
            >
              <h2>Langton's Ant with JS<i className="fas fa-external-link-alt"></i></h2>
            </ShowcaseItem> */}
          </ProjectsBoxes>
        </Container1200>
      </Projects>
    </Layout>
  )
}

export default OtherProjects;
