import styled from 'styled-components';
import { ShadowedBox } from '../ui.styles';

const Section = styled.section`
  margin-bottom: 70px;
`;

export const Intro = styled(Section)`

`;

export const Projects = styled(Section)`

`;

export const BlurbBox = styled.div`
  ${ShadowedBox};
  width: 100%;
  padding: 50px;
`;

export const Blurb = styled.p`
  font-size: 2rem;
  line-height: 130%;
  letter-spacing: .5px;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const ProjectsBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

